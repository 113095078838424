import { Divider } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '../atoms';

export function CompanySelector({
  companyId,
  companies,
  onChange,
}: {
  companies: { _id: string; name: string }[];
  companyId: string;
  onChange: (companyId: string) => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      selectAll: t('tables.labels.noCompaniesSelected'),
      textField: t('tables.labels.companySelector'),
    }),
    [t],
  );

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      onChange?.(value);
    },
    [onChange],
  );

  const options = useMemo(
    () => [
      { label: labels.selectAll, value: '' },
      () => <Divider />,
      ...companies.map(({ _id, name }, index) => ({
        label: name,
        menuItemProps: { sx: [index === 0 && { fontWeight: 'bold' }] },
        value: _id,
      })),
    ],
    [companies, labels.selectAll],
  );

  return (
    <TextField
      label={labels.textField}
      onChange={handleChange}
      options={options}
      select
      size="small"
      sx={{ width: '288px' }}
      value={companyId}
    />
  );
}
