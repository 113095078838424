import * as dateFns from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toCentimes, toChf } from '@sbiz/util-common';
import { getDateInFuture, getStartOfMonth } from '@sbiz/util-dates';

import { useApi } from '../../../common/api/hooks/useApi';
import { Check } from '../../../common/api/resources/check';
import { FormParts } from '../../../common/forms';
import { useFormErrorAlertProp } from '../../../hooks/useFormErrorAlertProp';
import { useProfileOptions } from '../../../hooks/useProfileOptions';
import { DateField } from '../../atoms';
import { DateFieldValidationError } from '../../atoms/DateField';
import { getFormattedNumber, SelectOption } from '../../atoms/TextField';
import { DialogFormProps } from '../../molecules/FormDialog';
import { Form } from '../../organisms/Form';

type CheckFormField = 'amount' | 'dayOfDistribution' | 'name' | 'profile';
export type CheckFormData = Record<CheckFormField, string>;

export function CheckForm({ check, name, onSubmit }: { check?: Check } & DialogFormProps) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      startDate: t('resources.check.propertyNames.startDate'),
    }),
    [t],
  );

  const [dayOfDistribution, setDayOfDistribution] = useState(check?.dayOfDistribution ?? 1);
  const [startDateError, setStartDateError] = useState<DateFieldValidationError>(null);
  const [minStartDate, setMinStartDate] = useState(getMinStartDate(dayOfDistribution));
  const [startDate, setStartDate] = useState(check?.lastDistributedAt ? new Date(check.startDate) : minStartDate);

  const { create, updateOne } = useApi('check');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const profileOptions = useProfileOptions();

  const dayOptions = useMemo(
    (): SelectOption[] =>
      Array.from({ length: 31 }, (_, index) => {
        const value = String(index + 1);
        return { label: value.padStart(2, '0'), value };
      }),
    [],
  );

  const defaultValues = useMemo(
    () => ({
      dayOfDistribution: String(dayOfDistribution),
      ...(check?._id && {
        amount: getFormattedNumber(toChf(check.amount)),
        name: check.name,
        profile: check.profile._id,
      }),
    }),
    [check?._id, check?.amount, check?.name, check?.profile?._id, dayOfDistribution],
  );

  const handleDayChange = useCallback(
    (value: string) => {
      const day = Number(value);
      setDayOfDistribution(day);

      const minStartDate = getMinStartDate(day);
      setMinStartDate(minStartDate);

      setStartDate((currentValue) => {
        if (check?.lastDistributedAt) {
          return currentValue;
        }

        return dateFns.max([currentValue, minStartDate]);
      });
    },
    [check?.lastDistributedAt],
  );

  const handleStartDateChange = useCallback((date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  }, []);

  const StartDate = useCallback(
    () => (
      <DateField
        label={labels.startDate}
        minDate={minStartDate}
        onChange={handleStartDateChange}
        onError={setStartDateError}
        readOnly={Boolean(check?.lastDistributedAt)}
        required
        value={startDate}
        views={['month', 'year']}
      />
    ),
    [check?.lastDistributedAt, handleStartDateChange, labels.startDate, minStartDate, startDate],
  );

  const parts = useMemo(
    (): FormParts<CheckFormData> => [
      { fieldName: 'name', props: { required: true } },
      { fieldName: 'profile', props: { options: profileOptions, required: true, select: true } },
      { fieldName: 'amount', fieldType: 'amount', props: { required: true } },
      [
        {
          fieldName: 'dayOfDistribution',
          onChange: handleDayChange,
          props: { options: dayOptions, required: true, select: true },
          tooltip: true,
        },
        StartDate,
      ],
    ],
    [dayOptions, handleDayChange, profileOptions, StartDate],
  );

  const handleSubmit = useCallback(
    async (data: CheckFormData) => {
      const formData = {
        ...data,
        startDate: startDate ? getStartOfMonth(startDate).toISOString() : '',
        amount: data.amount && toCentimes(data.amount),
        dayOfDistribution,
      };

      const options = { fetcher: { clear: 'check' } } as const;
      const { error } = await (check?._id ? updateOne(check._id, formData, options) : create(formData, options));

      setErrorAlert(error);

      if (!error) {
        onSubmit?.();
      }
    },
    [check?._id, create, onSubmit, setErrorAlert, startDate, updateOne, dayOfDistribution],
  );

  return (
    <Form
      alert={errorAlert}
      defaultValues={defaultValues}
      name={name}
      onSubmit={handleSubmit}
      parts={parts}
      resourceType="check"
      {...(startDateError && { submitBtnProps: { disabled: true } })}
    />
  );
}

function getMinStartDate(dayOfDistribution: number) {
  const currentMonthDayOfDistribution = dateFns.setDate(new Date(), dayOfDistribution);
  const tomorrow = getDateInFuture(1, 'days');

  if (currentMonthDayOfDistribution < tomorrow) {
    return getStartOfMonth(getDateInFuture(1, 'months'));
  } else {
    return getStartOfMonth();
  }
}
