import { JSX, useCallback, useMemo, useState } from 'react';

import { Csv, CsvDelimiter, CsvPreview, CsvRow, SafeApiResponse } from '../../common/api';
import { ResourceType } from '../../common/api/resources';
import { DialogFormProps, FormDialog, FormDialogCloseReason, FormDialogOverrideProps } from '../molecules/FormDialog';
import { useGetFieldLabel } from './Form/hooks/useGetFieldLabel';

export type FilePreviewFetcher = (file: File, delimiter?: CsvDelimiter) => Promise<SafeApiResponse<CsvPreview>>;

type FileFormProps<T = unknown> = { headers: CsvRow; previewFetcher: FilePreviewFetcher } & DialogFormProps<T>;
export type FileSelectionFormProps = FileFormProps<Csv>;
export type FileUploadFormProps = { csv: Csv; onFooterLinkClick: () => void } & FileFormProps;

type FileDialogProps = {
  fields: string[];
  getDialogProps?: (csv?: Csv) => Partial<FormDialogOverrideProps> | undefined;
  namePrefix: string;
  previewFetcher: FilePreviewFetcher;
  resourceType?: ResourceType;
  SelectionForm: (props: FileSelectionFormProps) => JSX.Element | null;
  UploadForm: (props: FileUploadFormProps) => JSX.Element | null;
} & FormDialogOverrideProps;

export function FileDialog({
  fields,
  getDialogProps,
  namePrefix,
  onClose,
  previewFetcher,
  resourceType,
  SelectionForm,
  UploadForm,
  ...props
}: FileDialogProps) {
  const [csv, setCsv] = useState<Csv>();
  const name = `${namePrefix}File${csv ? 'Upload' : 'Selection'}`;

  const getFieldLabel = useGetFieldLabel();

  const headers = useMemo(
    () => fields.map((field) => getFieldLabel(name, field, resourceType)),
    [fields, getFieldLabel, name, resourceType],
  );

  const handleClose = useCallback(
    (...args: [FormDialogCloseReason, unknown]) => {
      onClose(...args);
    },
    [onClose],
  );

  const handleFileSelection = useCallback((csv?: Csv) => {
    if (csv) {
      setCsv(csv);
    }
  }, []);

  const handleOpen = useCallback(() => {
    setCsv(undefined);
  }, []);

  const handleUploadFormLinkClick = useCallback(() => {
    setCsv(undefined);
  }, []);

  const Form = useCallback(
    ({ onSubmit, ...props }: DialogFormProps) => {
      const formProps = { headers, previewFetcher, ...props };

      if (csv) {
        return (
          <UploadForm csv={csv} onFooterLinkClick={handleUploadFormLinkClick} onSubmit={onSubmit} {...formProps} />
        );
      }

      return <SelectionForm onSubmit={handleFileSelection} {...formProps} />;
    },
    [csv, handleFileSelection, handleUploadFormLinkClick, headers, previewFetcher, SelectionForm, UploadForm],
  );

  return (
    <FormDialog
      Form={Form}
      onClose={handleClose}
      onOpen={handleOpen}
      name={name}
      {...getDialogProps?.(csv)}
      {...props}
    />
  );
}
