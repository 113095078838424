import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toCentimes, toChf, toMoney } from '@sbiz/util-common';

import { useApi } from '../../../common/api/hooks/useApi';
import { GiftCard } from '../../../common/api/resources/gift-card';
import { FormParts } from '../../../common/forms';
import { useFormErrorAlertProp } from '../../../hooks/useFormErrorAlertProp';
import { Html, TextField } from '../../atoms';
import { ButtonProps } from '../../atoms/Button';
import { DialogFormProps } from '../../molecules/FormDialog';
import { Form } from '../../organisms';

type GiftCardFormData = Record<'amount' | 'nameForClient' | 'quantity', string>;

export function GiftCardForm({ giftCard, onSubmit, ...props }: { giftCard?: GiftCard } & DialogFormProps) {
  const [amount, setAmount] = useState(giftCard?.amount ?? 0);
  const [quantity, setQuantity] = useState(giftCard?.quantity ?? 1);

  const { create } = useApi('giftCard');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    if (giftCard?.amount) {
      return {
        amount: toChf(giftCard.amount).toFixed(2),
        nameForClient: giftCard.nameForClient,
        quantity: giftCard.quantity.toFixed(0),
      };
    }
  }, [giftCard?.amount, giftCard?.nameForClient, giftCard?.quantity]);

  const total = useMemo(() => toMoney(amount * quantity), [amount, quantity]);

  const labels = useMemo(
    () => ({
      confirmation: t('forms.giftCard.confirmation', { amount: toMoney(amount), quantity, total }),
      total: t('resources.propertyNames.totalAmount'),
    }),
    [amount, quantity, t, total],
  );

  const Total = useCallback(
    () => <TextField helperText=" " slotProps={{ input: { readOnly: true } }} label={labels.total} value={total} />,
    [labels.total, total],
  );

  const submitBtnProps = useMemo(
    (): ButtonProps => getGiftCardSubmitBtnProps(labels.confirmation),
    [labels.confirmation],
  );

  const parts = useMemo(
    (): FormParts<GiftCardFormData> => [
      { fieldName: 'nameForClient', props: { required: true } },
      {
        onChange: (value) => {
          setQuantity(value ? parseInt(value, 10) : 1);
        },
        fieldName: 'quantity',
        fieldType: 'number',
        props: { htmlInputProps: { min: 1, step: 1 }, required: true },
      },
      {
        onChange: (value) => {
          setAmount(toCentimes(value));
        },
        fieldName: 'amount',
        fieldType: 'amount',
        props: { htmlInputProps: { min: 0.1 }, required: true },
      },
      Total,
    ],
    [Total],
  );

  const handleSubmit = useCallback(
    async (formData: GiftCardFormData) => {
      const { error } = await create(
        { ...formData, amount: toCentimes(formData.amount), quantity: parseInt(formData.quantity, 10) },
        { fetcher: { clear: 'giftCard' } },
      );

      setErrorAlert(error);

      if (!error) {
        onSubmit?.();
      }
    },
    [create, onSubmit, setErrorAlert],
  );

  return (
    <Form
      alert={errorAlert}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      parts={parts}
      readOnly={Boolean(giftCard)}
      resourceType="giftCard"
      submitBtnProps={submitBtnProps}
      {...props}
    />
  );
}

export function getGiftCardSubmitBtnProps(text: string) {
  return {
    confirmProps: {
      children: (
        <Html
          content={text}
          sx={{
            '& ul': { listStyle: 'inside', mb: 3, mt: 2, '& > li': { my: 1 } },
            '& strong': { fontWeight: 'bold' },
          }}
        />
      ),
    },
  };
}
