import { List, ListItem, ListSubheader } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyRebateItem } from '@sbiz/business';
import { toCentimes, toChf, toMoney } from '@sbiz/util-common';

import { FormApi, FormParts } from '../../../../common/forms';
import { styledProps } from '../../../../common/styles';
import { AddButton, DeleteButton, FlexBox, Span } from '../../../atoms';
import { IconButtonOverrideProps } from '../../../atoms/IconButton';
import { HELPER_TEXT_HEIGHT } from '../../../atoms/TextField';
import { DialogFormProps, FormDialog, FormDialogOverrideProps } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms/Form';
import { useGetErrorLabel } from '../../../organisms/Form/hooks/useGetErrorLabel';

const FORM_NAME = 'rebateUpdate';
const PREFIX_LENGTH = 3;
const MAX_REBATE_ITEM_COUNT = 12;

type RebateFieldName = `${string}_${'threshold' | 'value'}`;
type RebateFormData = Record<RebateFieldName, string>;

export function RebateDialog({
  defaultRebateItems,
  rebateItems,
  ...dialogProps
}: { defaultRebateItems: CompanyRebateItem[]; rebateItems?: CompanyRebateItem[] } & FormDialogOverrideProps<
  CompanyRebateItem[]
>) {
  const Form = useCallback(
    (props: DialogFormProps<CompanyRebateItem[]>) => (
      <RebateForm defaultRebateItems={defaultRebateItems} rebateItems={rebateItems} {...props} />
    ),
    [defaultRebateItems, rebateItems],
  );

  return <FormDialog disableFeedback Form={Form} name={FORM_NAME} {...dialogProps} />;
}

export function RebateForm({
  defaultRebateItems,
  onSubmit,
  rebateItems: initialRebateItems,
  ...props
}: { defaultRebateItems: CompanyRebateItem[]; rebateItems?: CompanyRebateItem[] } & DialogFormProps<
  CompanyRebateItem[]
>) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      defaultItems: t(`forms.${FORM_NAME}.defaultItems`),
      item: (threshold: string, percentage: string) =>
        t('resources.company.propertyValues.items', { percentage, threshold }),
      noItems: t(`forms.${FORM_NAME}.noItems`),
      percentage: t([
        'resources.company.propertyNames.rebate_items_value_percentage',
        'resources.propertyNames.percentage',
      ]),
      threshold: t('resources.company.propertyNames.rebate_items_threshold'),
    }),
    [t],
  );

  const [isError, setIsError] = useState(false);
  const [rebateItems, setRebateItems] = useState(
    () => initialRebateItems?.map(({ threshold, value: { percentage } }) => ({ threshold, percentage })) ?? [],
  );
  const isAddDisabled = isError || rebateItems.length >= MAX_REBATE_ITEM_COUNT;

  const apiRef = useRef<FormApi<RebateFormData>>(null);

  const getErrorLabel = useGetErrorLabel();

  const rebateEntries = useMemo(
    () =>
      rebateItems.map(({ threshold, percentage }, index): [RebateFieldName, string][] => {
        const prefix = getPrefix(index);
        return [
          [`${prefix}_threshold`, toChf(threshold).toFixed(2)],
          [`${prefix}_value`, percentage.toFixed(2)],
        ];
      }),
    [rebateItems],
  );

  const defaultValues = useMemo((): RebateFormData => Object.fromEntries(rebateEntries.flat(1)), [rebateEntries]);

  const addRow = useCallback(
    (index: number) => () => {
      const prefix = getPrefix(index);
      const thresholdFieldName: RebateFieldName = `${prefix}_threshold`;
      const valueFieldName: RebateFieldName = `${prefix}_value`;

      apiRef.current?.mutate((currentValue) => {
        const values = { ...currentValue };

        for (const [fieldName, value] of Object.entries(values) as [RebateFieldName, string][]) {
          const fieldIndex = getFieldIndex(fieldName);
          if (fieldIndex >= index) {
            values[getNextFieldName(fieldName)] = value;
          }
        }

        values[thresholdFieldName] = '';
        values[valueFieldName] = '';

        return values;
      });

      apiRef.current?.setDirtyFields(() => new Set());

      setRebateItems((currentValue) => [
        ...currentValue.slice(0, index),
        { threshold: 0, percentage: 0 },
        ...currentValue.slice(index),
      ]);
    },
    [],
  );

  const deleteRow = useCallback(
    (index: number) => () => {
      apiRef.current?.mutate((currentValue) => {
        const values = { ...currentValue };

        for (const [fieldName, value] of Object.entries(values) as [RebateFieldName, string][]) {
          const fieldIndex = getFieldIndex(fieldName);
          if (fieldIndex > index) {
            values[getPreviousFieldName(fieldName)] = value;
          }
        }

        return values;
      });

      setRebateItems((currentValue) => [...currentValue.slice(0, index), ...currentValue.slice(index + 1)]);
    },
    [],
  );

  const getError = useCallback(
    (fieldName: RebateFieldName) => (value: string, formData: RebateFormData) => {
      const nextFieldName = getNextFieldName(fieldName);
      const nextValue = formData[nextFieldName];
      const previousFieldName = getPreviousFieldName(fieldName);
      const previousValue = formData[previousFieldName];

      let isBiggerThanNext = false;
      let isSmallerThanPrevious = false;

      if (nextValue && value && Number(nextValue) <= Number(value)) {
        isBiggerThanNext = true;
      }

      if (previousValue && value && Number(previousValue) >= Number(value)) {
        isSmallerThanPrevious = true;
      }

      if (isBiggerThanNext || isSmallerThanPrevious) {
        const fieldKey = fieldName.substring(PREFIX_LENGTH + 1);
        const minLabel = isSmallerThanPrevious
          ? getErrorLabel(FORM_NAME, fieldKey, 'min')({ value: getValueDisplay(fieldName, previousValue) })
          : '';
        const maxLabel = isBiggerThanNext
          ? getErrorLabel(FORM_NAME, fieldKey, 'max')({ value: getValueDisplay(fieldName, nextValue) })
          : '';

        return `${minLabel}${minLabel && maxLabel ? '. ' : ''}${maxLabel}`;
      }
    },
    [getErrorLabel],
  );

  const handleChange = useCallback(() => {
    const errors = apiRef.current?.getErrors();
    setIsError(Boolean(errors?.size));
  }, []);

  const LastRow = useCallback(
    () =>
      rebateEntries.length ? (
        <AddRowButton disabled={isAddDisabled} onClick={addRow(rebateEntries.length)} />
      ) : (
        <>
          <FlexBox sx={{ alignItems: 'center', gap: 1 }}>
            <Span>{labels.noItems}</Span>
            <AddButton size="small" onClick={addRow(0)} />
          </FlexBox>

          <List subheader={<ListSubheader>{labels.defaultItems}</ListSubheader>}>
            {defaultRebateItems?.map(({ threshold, value: { percentage } }, index) => (
              <ListItem key={index}>{labels.item(toMoney(threshold), percentage.toFixed(2))}</ListItem>
            ))}
          </List>
        </>
      ),
    [addRow, defaultRebateItems, isAddDisabled, labels, rebateEntries.length],
  );

  const parts = useMemo(() => {
    const parts: FormParts<RebateFormData> = [
      ...rebateEntries.flatMap(([[thresholdFieldName], [valueFieldName]], index) => [
        () => <AddRowButton disabled={isAddDisabled} onClick={addRow(index)} />,
        [
          {
            fieldName: thresholdFieldName,
            fieldType: 'amount',
            getError: getError(thresholdFieldName),
            props: { label: labels.threshold, required: true },
          },
          {
            fieldName: valueFieldName,
            fieldType: 'number',
            getError: getError(valueFieldName),
            props: { label: labels.percentage, required: true },
          },
          () => (
            <FlexBox sx={{ height: '100%', pb: `${HELPER_TEXT_HEIGHT}px`, position: 'absolute', right: -40 }}>
              <DeleteButton onClick={deleteRow(index)} size="small" sx={{ alignSelf: 'center' }} />
            </FlexBox>
          ),
        ],
      ]),
      LastRow,
    ];

    return parts;
  }, [addRow, deleteRow, getError, isAddDisabled, labels, LastRow, rebateEntries]);

  const boxProps = useMemo(() => {
    if (rebateEntries.length) {
      return { sx: { pt: 5 } };
    }
  }, [rebateEntries.length]);

  const submitBtnProps = useMemo(() => ({ sx: { mt: 4 } }), []);

  const handleSubmit = useCallback(() => {
    const formData = apiRef.current?.getFormData() ?? null;
    const values = formData && Object.values(formData);

    const rebateItems: CompanyRebateItem[] = [];
    if (values) {
      for (let index = 0; index < values.length; index += 2) {
        rebateItems.push({ threshold: toCentimes(values[index]), value: { percentage: Number(values[index + 1]) } });
      }
    }

    onSubmit(rebateItems.length ? rebateItems : undefined);
  }, [onSubmit]);

  return (
    <Form
      apiRef={apiRef}
      boxProps={boxProps}
      defaultValues={defaultValues}
      disableAutoFocus
      parts={parts}
      onChange={handleChange}
      onSubmit={handleSubmit}
      submitBtnProps={submitBtnProps}
      {...props}
    />
  );
}

function AddRowButton(props: Omit<IconButtonOverrideProps, 'size'>) {
  const buttonProps = useMemo(() => styledProps({ bottom: 0, position: 'absolute' }, props), [props]);

  return (
    <FlexBox sx={{ justifyContent: 'center', position: 'relative' }}>
      <AddButton size="small" {...buttonProps} />
    </FlexBox>
  );
}

function getFieldIndex(fieldName: RebateFieldName) {
  return Number(fieldName.substring(0, PREFIX_LENGTH));
}

function getNeighbourFieldName(fieldName: RebateFieldName, delta: 1 | -1) {
  const neighbourIndex = getFieldIndex(fieldName) + delta;
  const neighbourName = fieldName.substring(PREFIX_LENGTH + 1);
  return `${getPrefix(neighbourIndex)}_${neighbourName}` as RebateFieldName;
}

function getNextFieldName(fieldName: RebateFieldName) {
  return getNeighbourFieldName(fieldName, 1);
}

function getPreviousFieldName(fieldName: RebateFieldName) {
  return getNeighbourFieldName(fieldName, -1);
}

function getPrefix(index: number) {
  return String(index).padStart(PREFIX_LENGTH, '0');
}

function getValueDisplay(fieldName: RebateFieldName, value: string) {
  return fieldName.endsWith('threshold') ? toMoney(value, 'chf') : `${value}%`;
}
