import { useCallback, useMemo } from 'react';

import { useApi } from '../../../../common/api/hooks/useApi';
import { FormOverrideProps, FormParts } from '../../../../common/forms';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { useGetRedirectionUrl } from '../../../../hooks/useGetRedirectionUrl';
import { useLang } from '../../../../hooks/useLang';
import { useTabQueryParam } from '../../../../hooks/useTabQueryParam';
import { useTargetCompanyIdQueryParam } from '../../../../hooks/useTargetCompanyIdQueryParam';
import { Form } from '../../../organisms/Form/Form';

type CreditCardFormData = Record<'cardholderEmail' | 'cardholderName' | 'cardName', string>;

export function CreditCardForm({
  onChange,
  ...props
}: Omit<FormOverrideProps<CreditCardFormData>, 'onChange'> & {
  onChange?: (transactionId: string) => void;
}) {
  const { post } = useApi('datatrans');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const getRedirectionUrl = useGetRedirectionUrl();
  const [lang] = useLang();
  const [tabParam] = useTabQueryParam();
  const [companyParam] = useTargetCompanyIdQueryParam();

  const parts = useMemo(
    (): FormParts<CreditCardFormData> => [
      { fieldName: 'cardName', props: { required: true } },
      { fieldName: 'cardholderName', props: { required: true } },
      { fieldName: 'cardholderEmail', fieldType: 'email', props: { required: true } },
    ],
    [],
  );

  const handleSubmit = useCallback(
    async (formData: CreditCardFormData) => {
      const redirectUrl = getRedirectionUrl({
        searchParams: new URLSearchParams({
          ...(companyParam && { [companyParam.name]: companyParam.value }),
          ...(tabParam && { [tabParam.name]: tabParam.value }),
        }),
      });

      const { data, error } = await post<{ transactionId: string }>('transactions/authenticate', {
        data: { ...formData, language: lang, redirectUrl: `${window.origin}/${redirectUrl}` },
      });

      if (error) {
        setErrorAlert(error);
      } else {
        onChange?.(data.transactionId);
      }
    },
    [companyParam, getRedirectionUrl, lang, onChange, post, setErrorAlert, tabParam],
  );

  return <Form alert={errorAlert} name="creditCard" onSubmit={handleSubmit} parts={parts} {...props} />;
}
