import { useCallback, useMemo } from 'react';

import { TABLE_CONFIG_PARAM_NAME } from '../common/pages';
import { TableName } from '../common/tables';
import { TableConfig } from '../components/organisms/Table';
import { useQueryParam } from './useQueryParam';

export function useTableConfigQueryParam<T = Record<string, unknown>>(tableName: TableName) {
  const [configParam, setConfigParam] = useQueryParam(TABLE_CONFIG_PARAM_NAME);

  const config = useMemo(() => {
    if (configParam?.value) {
      const config = JSON.parse(configParam.value);
      return config[tableName] as TableConfig<T>;
    }
  }, [configParam?.value, tableName]);

  const setParam = useCallback(
    (valueJSON: string | undefined) => {
      const value = typeof valueJSON === 'string' ? JSON.stringify({ [tableName]: JSON.parse(valueJSON) }) : undefined;
      setConfigParam(value);
    },
    [setConfigParam, tableName],
  );

  return [config, setParam] as const;
}
