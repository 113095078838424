import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyJson, CompanyRebateItem } from '@sbiz/business';
import { pick, toCentimes, toChf, toMoney } from '@sbiz/util-common';

import { useApi } from '../../../../common/api/hooks/useApi';
import { CompanySettings } from '../../../../common/api/resources/company';
import { FormApi, FormParts, isValidEmail } from '../../../../common/forms';
import { useAddressAutocomplete } from '../../../../hooks/useAddressAutoComplete';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { useGetOfferedMonthsLabel } from '../../../../hooks/useOfferedMonthsLabel';
import { EditButton, FlexBox, Span } from '../../../atoms';
import { ButtonProps } from '../../../atoms/Button';
import { HELPER_TEXT_HEIGHT } from '../../../atoms/TextField';
import { DialogFormProps, FormDialogCloseReason } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms';
import { useGetErrorLabel } from '../../../organisms/Form/hooks/useGetErrorLabel';
import { useGetFieldLabel } from '../../../organisms/Form/hooks/useGetFieldLabel';
import { RebateDialog } from './RebateDialog';
import { ServiceFeeOffersDialog } from './ServiceFeeOffersDialog';

const COMPANY_INVOICING_FIELDS = [
  'companyRegister',
  'contactEmailFinance',
  'invoiceContactName',
  'lang',
  'paymentTerms',
  'prefix',
  'purchaseOrderNumber',
  'receivableLimit',
  'vatNumber',
] as const;
type CompanyInvoicingField = (typeof COMPANY_INVOICING_FIELDS)[number];
type CompanyInvoicingFormData = Record<'rebate' | 'serviceFeePercentage' | CompanyInvoicingField, string> & {
  'fees.service.isCharged': boolean;
  isStatementBlackAndWhite: boolean;
  isStatementDetailed: boolean;
  'rebate.isActive': boolean;
  shouldEmailForNewInvoice: boolean;
};

const COMPANY_PREFIX_REGEX = /^[a-z]{0,3}$/i;

const MIN_PERCENTAGE = 0.01;

export function CompanyInvoicingForm({
  company,
  onSubmit,
  settings: { rebate: defaultRebateItems, fees, offeredMonths: defaultOfferedMonths, prefix: defaultPrefix },
  ...props
}: { company: CompanyJson; settings: CompanySettings } & DialogFormProps) {
  const defaultPercentage = (fees?.service?.percentage ?? MIN_PERCENTAGE).toFixed(2);
  const formName = props.name;

  const getErrorLabel = useGetErrorLabel();
  const getFieldLabel = useGetFieldLabel();
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      contactEmailFinanceHelperText: t(`forms.${formName}.fields.contactEmailFinance.helperText`),
      contactEmailFinanceInvalid: getErrorLabel(formName, 'contactEmailFinance', 'invalidEmail')(),
      firstRebateThreshold: (threshold: string, percentage: string) =>
        t('resources.company.propertyValues.items', { percentage, threshold }),
      invoiceAddress: getFieldLabel(formName, 'invoiceAddress', 'company'),
      paymentTermsHelperText: t(`forms.${formName}.fields.paymentTerms.helperText`),
      percentageHelperText: t(`forms.${formName}.fields.serviceFeePercentage.helperText`, {
        percentage: defaultPercentage,
      }),
      prefixHelperText: t(`forms.${formName}.fields.prefix.helperText`, { prefix: defaultPrefix }),
    }),
    [defaultPercentage, defaultPrefix, getErrorLabel, getFieldLabel, formName, t],
  );

  const defaultValues = useMemo(
    () =>
      ({
        ...pick(company, COMPANY_INVOICING_FIELDS),
        contactEmailFinance: company.contactEmailFinance?.join(', '),
        'fees.service.isCharged': Boolean(company.fees?.service?.isCharged),
        isStatementBlackAndWhite: company.invoiceColorTheme === 'bnw',
        isStatementDetailed: company.invoiceFormat === 'detailed',
        paymentTerms: String(company.paymentTerms ?? 0),
        'rebate.isActive': Boolean(company.rebate?.isActive),
        receivableLimit: toChf(company.receivableLimit).toFixed(2),
        serviceFeePercentage: company.fees?.service?.percentage?.toFixed(2),
        shouldEmailForNewInvoice: Boolean(company.shouldEmailForNewInvoice),
      }) satisfies Partial<CompanyInvoicingFormData>,
    [company],
  );

  const [emails, setEmails] = useState(company.contactEmailFinance);
  const [isOffersDialogOpen, setIsOffersDialogOpen] = useState(false);
  const [isRebateActive, setIsRebateActive] = useState(defaultValues['rebate.isActive']);
  const [isRebateDialogOpen, setIsRebateDialogOpen] = useState(false);
  const [isServiceFeeCharged, setIsServiceFeeCharged] = useState(Boolean(company.fees?.service?.isCharged));
  const [isStatementEmailed, setIsStatementEmailed] = useState(defaultValues.shouldEmailForNewInvoice);
  const [monthSelection, setMonthSelection] = useState<Set<number>>();
  const [paymentTerms, setPaymentTerms] = useState(company.paymentTerms ?? 0);
  const [rebateItems, setRebateItems] = useState(company.rebate?.items);

  const formApiRef = useRef<FormApi<CompanyInvoicingFormData>>(null);

  const { patch: patchOffers } = useApi('offer');
  const { updateOne } = useApi('company');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const getOfferedMonthsLabel = useGetOfferedMonthsLabel();
  const isAdmin = useIsEditor('adminCompanies');

  const { search: invoiceAddress, Autocomplete: InvoiceAddressAutocomplete } = useAddressAutocomplete({
    defaultValue: company.invoiceAddress ?? company.postalAddress,
  });

  const offeredMonths = useMemo(() => {
    if (monthSelection) {
      return Array.from({ length: defaultOfferedMonths.length }, (_, index) => monthSelection.has(index));
    }

    return defaultOfferedMonths;
  }, [defaultOfferedMonths, monthSelection]);

  const closeOffersDialog = useCallback((reason: FormDialogCloseReason, selection?: Set<number>) => {
    if (reason === 'formSubmission') {
      setMonthSelection(selection);
    }

    setIsOffersDialogOpen(false);
  }, []);

  const closeRebateDialog = useCallback((reason: FormDialogCloseReason, rebateItems?: CompanyRebateItem[]) => {
    if (reason === 'formSubmission') {
      setRebateItems(rebateItems);
    }

    setIsRebateDialogOpen(false);
  }, []);

  const OffersButton = useCallback(() => {
    const label = getOfferedMonthsLabel(defaultOfferedMonths, monthSelection, `forms.${formName}`);

    return (
      <FlexBox
        sx={[
          { alignItems: 'center', gap: 1, pb: `${HELPER_TEXT_HEIGHT}px` },
          !isServiceFeeCharged && { visibility: 'hidden' },
        ]}
      >
        <Span>{label}</Span>
        <EditButton onClick={() => setIsOffersDialogOpen(true)} />
      </FlexBox>
    );
  }, [defaultOfferedMonths, formName, getOfferedMonthsLabel, isServiceFeeCharged, monthSelection]);

  const RebateButton = useCallback(() => {
    const [
      {
        threshold,
        value: { percentage },
      },
    ] = rebateItems ?? defaultRebateItems;

    return (
      <FlexBox
        sx={[
          { alignItems: 'center', gap: 1, pb: `${HELPER_TEXT_HEIGHT}px` },
          !isRebateActive && { visibility: 'hidden' },
        ]}
      >
        <Span>{labels.firstRebateThreshold(toMoney(threshold), percentage.toFixed(2))}</Span>
        <EditButton
          onClick={() => {
            setIsRebateDialogOpen(true);
          }}
        />
      </FlexBox>
    );
  }, [defaultRebateItems, isRebateActive, labels, rebateItems]);

  const parts = useMemo(() => {
    const parts: FormParts<CompanyInvoicingFormData> = [
      { fieldName: 'companyRegister', props: { slotProps: { input: { readOnly: !isAdmin, required: true } } } },
      () => <InvoiceAddressAutocomplete freeSolo textFieldProps={{ label: labels.invoiceAddress, required: true }} />,
      [
        { fieldName: 'vatNumber', props: { required: true } },
        { fieldName: 'purchaseOrderNumber' },
        {
          fieldName: 'prefix',
          getError: (value) => {
            const prefixValue = value as CompanyInvoicingFormData['prefix'];
            if (prefixValue && prefixValue.length < 3) {
              return labels.prefixHelperText;
            }
          },
          props: {
            helperText: labels.prefixHelperText,
            htmlInputProps: { pattern: COMPANY_PREFIX_REGEX },
            slotProps: { input: { sx: { '& input': { textTransform: 'uppercase' } } } },
          },
        },
      ],
      [{ fieldName: 'invoiceContactName' }, { fieldName: 'lang', fieldType: 'lang-select', props: { required: true } }],
      {
        fieldName: 'shouldEmailForNewInvoice',
        fieldType: 'switch',
        onChange: (checked: unknown) => {
          setIsStatementEmailed(checked as boolean);
        },
        tooltip: true,
      },
      {
        fieldName: 'contactEmailFinance',
        getError: () => {
          if (emails?.some((email) => !isValidEmail(email))) {
            return labels.contactEmailFinanceInvalid;
          }
        },
        onChange: (value) => {
          const emailList = value as string;
          setEmails(emailList.split(/,\s*|\s+/).filter(Boolean));
        },
        props: {
          helperText: labels.contactEmailFinanceHelperText,
          required: isStatementEmailed,
          slotProps: {
            htmlInput: {
              onBlur: () => {
                formApiRef.current?.mutateField('contactEmailFinance', emails?.join(', ') ?? '');
              },
            },
          },
        },
      },
      [
        { fieldName: 'isStatementDetailed', fieldType: 'switch', tooltip: true },
        { fieldName: 'isStatementBlackAndWhite', fieldType: 'switch' },
      ],
    ];

    if (isAdmin) {
      parts.push(
        [
          { fieldName: 'receivableLimit', fieldType: 'amount' },
          {
            fieldName: 'paymentTerms',
            fieldType: 'number',
            onChange: (value) => {
              setPaymentTerms(Number(value));
            },
            props: {
              htmlInputProps: { step: 1 },
              ...(paymentTerms > 30 && {
                FormHelperTextProps: { sx: { color: 'warning.main' } },
                helperText: labels.paymentTermsHelperText,
              }),
            },
          },
        ],
        [
          {
            fieldName: 'fees.service.isCharged',
            fieldType: 'switch',
            onChange: (checked: unknown) => {
              setIsServiceFeeCharged(checked as boolean);
            },
          },
          {
            fieldName: 'serviceFeePercentage',
            fieldType: 'number',
            isHidden: !isServiceFeeCharged,
            props: {
              helperText: labels.percentageHelperText,
              htmlInputProps: { min: MIN_PERCENTAGE, max: 100 },
            },
          },
          OffersButton,
        ],
        [
          {
            fieldName: 'rebate.isActive',
            fieldType: 'switch',
            onChange: (checked: unknown) => {
              setIsRebateActive(checked as boolean);
            },
            props: { slotProps: { formControl: { sx: { flex: 'unset' } } } },
          },
          RebateButton,
        ],
      );
    }

    return parts;
  }, [
    emails,
    InvoiceAddressAutocomplete,
    isAdmin,
    isServiceFeeCharged,
    isStatementEmailed,
    labels.contactEmailFinanceHelperText,
    labels.contactEmailFinanceInvalid,
    labels.invoiceAddress,
    labels.paymentTermsHelperText,
    labels.percentageHelperText,
    labels.prefixHelperText,
    OffersButton,
    paymentTerms,
    RebateButton,
  ]);

  const submitBtnProps = useMemo(() => {
    if (!invoiceAddress || paymentTerms > 30) {
      const submitBtnProps: ButtonProps = {};

      if (!invoiceAddress) {
        submitBtnProps.disabled = true;
      }

      if (paymentTerms > 30) {
        submitBtnProps.confirmProps = { name: 'invoicingPaymentTermsWarning' };
      }

      return submitBtnProps;
    }
  }, [invoiceAddress, paymentTerms]);

  const setOfferedMonths = useCallback(async () => {
    if (monthSelection) {
      const { error } = await patchOffers('months', {
        data: { target: 'fees.service', indexes: Array.from(monthSelection) },
      });
      setErrorAlert(error);

      if (error) {
        return;
      }
    }

    onSubmit?.();
  }, [monthSelection, onSubmit, patchOffers, setErrorAlert]);

  const handleSubmit = useCallback(
    async ({
      isStatementBlackAndWhite,
      isStatementDetailed,
      paymentTerms,
      prefix,
      receivableLimit,
      serviceFeePercentage,
      ...formData
    }: CompanyInvoicingFormData) => {
      const { error } = await updateOne('', {
        ...formData,
        contactEmailFinance: emails?.length ? emails : null,
        invoiceAddress,
        invoiceColorTheme: isStatementBlackAndWhite ? 'bnw' : 'color',
        invoiceFormat: isStatementDetailed ? 'detailed' : 'default',
        prefix: prefix.toUpperCase(),
        ...(isAdmin && {
          'fees.service.percentage': serviceFeePercentage && Number(serviceFeePercentage),
          paymentTerms: paymentTerms ? Number(paymentTerms) : 0,
          'rebate.items': rebateItems ?? null,
          receivableLimit: toCentimes(receivableLimit),
        }),
      });

      setErrorAlert(error);

      if (!error) {
        setOfferedMonths();
      }
    },
    [emails, isAdmin, invoiceAddress, rebateItems, setErrorAlert, setOfferedMonths, updateOne],
  );

  return (
    <>
      <Form
        alert={errorAlert}
        apiRef={formApiRef}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        parts={parts}
        resourceType="company"
        submitBtnProps={submitBtnProps}
        {...props}
      />

      <ServiceFeeOffersDialog onClose={closeOffersDialog} offeredMonths={offeredMonths} open={isOffersDialogOpen} />

      <RebateDialog
        defaultRebateItems={defaultRebateItems}
        onClose={closeRebateDialog}
        open={isRebateDialogOpen}
        rebateItems={rebateItems}
      />
    </>
  );
}
