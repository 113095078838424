import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { pick } from '@sbiz/util-common';

import { ApiFetcherOptions, QueryValues } from '../../../common/api';
import { useApi } from '../../../common/api/hooks/useApi';
import { EmployeeInvitation } from '../../../common/api/resources/employee-invitation';
import { FormParts } from '../../../common/forms';
import { useFormErrorAlertProp } from '../../../hooks/useFormErrorAlertProp';
import { useIsEditor } from '../../../hooks/useIsEditor';
import { useLang } from '../../../hooks/useLang';
import { useProfileOptions } from '../../../hooks/useProfileOptions';
import { DateField, TextField } from '../../atoms';
import { DateFieldValidationError } from '../../atoms/DateField';
import { DialogFormProps } from '../../molecules/FormDialog';
import { Form } from '../../organisms/Form';

const EMPLOYEE_FORM_FIELDS = ['email', 'employeeId', 'firstname', 'lang', 'lastname', 'profile'] as const;

type EmployeeFormField = (typeof EMPLOYEE_FORM_FIELDS)[number];
type EmployeeFormData = Record<EmployeeFormField, string> & { isEnrollment?: boolean };

export function EmployeeForm({ employee, name, onSubmit }: { employee?: EmployeeInvitation } & DialogFormProps) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      endDate: t('resources.employee.propertyNames.endDate'),
      feedbackText: (isEnrollment?: boolean) => (isEnrollment ? t('feedbacks.employeeEnrollment.success') : undefined),
      smoodEmail: t('forms.employee.fields.smoodEmail.label'),
      startDate: t('resources.employee.propertyNames.startDate'),
    }),
    [t],
  );

  const [dateError, setDateError] = useState<DateFieldValidationError>(null);
  const [endDate, setEndDate] = useState<Date | null>(employee?.endDate ? new Date(employee?.endDate) : null);
  const [maxStartDate, setMaxStartDate] = useState<Date>();
  const [minEndDate, setMinEndDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date | null>(employee?.startDate ? new Date(employee?.startDate) : null);

  const { updateOne } = useApi('employee');
  const { create: invite } = useApi('employeeInvitation');
  const { create: adminInvite } = useApi('employeeInvitationAdmin');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const isAdmin = useIsEditor('adminEmployees');
  const [lang] = useLang();
  const profileOptions = useProfileOptions();

  const defaultValues = useMemo((): Partial<EmployeeFormData> => {
    if (employee) {
      return { ...pick(employee, EMPLOYEE_FORM_FIELDS), profile: employee.profile._id };
    }

    return { lang };
  }, [employee, lang]);

  const handleEndDateChange = useCallback((value: Date | null) => {
    setEndDate(value);
    setMaxStartDate(value ?? undefined);
  }, []);

  const handleStartDateChange = useCallback((value: Date | null) => {
    setStartDate(value);
    setMinEndDate(value ?? undefined);
  }, []);

  const dateFields = useMemo(
    () => [
      () => (
        <DateField
          label={labels.startDate}
          maxDate={maxStartDate}
          onChange={handleStartDateChange}
          onError={setDateError}
          required={Boolean(employee?.startDate)}
          value={startDate}
        />
      ),
      () => (
        <DateField
          label={labels.endDate}
          minDate={minEndDate}
          onChange={handleEndDateChange}
          onError={setDateError}
          value={endDate}
        />
      ),
    ],
    [
      employee?.startDate,
      endDate,
      handleEndDateChange,
      handleStartDateChange,
      labels.startDate,
      labels.endDate,
      maxStartDate,
      minEndDate,
      startDate,
    ],
  );

  const inviteEmployee = useCallback(
    (data: QueryValues<EmployeeInvitation>, isEnrollment?: boolean, fetcher?: ApiFetcherOptions) =>
      isAdmin && isEnrollment ? adminInvite(data, { fetcher, path: 'enroll' }) : invite(data, { fetcher }),
    [adminInvite, invite, isAdmin],
  );

  const handleSubmit = useCallback(
    async ({ isEnrollment, ...formData }: EmployeeFormData) => {
      const data = {
        ...formData,
        endDate: endDate && endOfDay(endDate).toISOString(),
        startDate: startDate && startOfDay(startDate).toISOString(),
      };

      const fetcher: ApiFetcherOptions = { clear: ['employee', 'employeeInvitation'] };
      const { error } = await (employee?._id
        ? updateOne(employee._id, data, { fetcher })
        : inviteEmployee(data, isEnrollment, fetcher));

      setErrorAlert(error);

      if (!error) {
        onSubmit?.(undefined, { feedbackText: labels.feedbackText(isEnrollment) });
      }
    },
    [employee?._id, endDate, inviteEmployee, labels, onSubmit, setErrorAlert, startDate, updateOne],
  );

  const parts = useMemo(
    (): FormParts<EmployeeFormData> => [
      { fieldName: 'firstname', props: { required: true } },
      { fieldName: 'lastname', props: { required: true } },
      { fieldName: 'email', fieldType: 'email', props: { required: true } },
      ...(!employee?._id && isAdmin ? [{ fieldName: 'isEnrollment', fieldType: 'switch' } as const] : []),
      { fieldName: 'employeeId' },
      ...(employee?._id
        ? [
            () => (
              <TextField
                helperText=" "
                label={labels.smoodEmail}
                slotProps={{ input: { readOnly: true } }}
                type="email"
                value={employee.customer?.email ?? ''}
              />
            ),
          ]
        : []),
      [
        { fieldName: 'profile', props: { options: profileOptions, required: true, select: true } },
        {
          fieldName: 'lang',
          fieldType: 'lang-select',
          props: { required: true, sx: { flexGrow: 0.35 } },
        },
      ],
      dateFields,
    ],
    [dateFields, employee?._id, employee?.customer?.email, isAdmin, labels.smoodEmail, profileOptions],
  );

  return (
    <Form
      alert={errorAlert}
      defaultValues={defaultValues}
      name={name}
      onSubmit={handleSubmit}
      parts={parts}
      resourceType="employeeInvitation"
      {...(dateError && { submitBtnProps: { disabled: true } })}
    />
  );
}
