import { useCallback, useMemo, useRef } from 'react';

import { TableName } from '../common/tables';
import { TableConfig } from '../components/organisms/Table';
import { useStorageItem } from './useStorageItem';
import { useTableConfigQueryParam } from './useTableConfigQueryParam';

export function useTableConfig<T = Record<string, unknown>>(tableName: TableName) {
  const [configParam, setConfigQueryParam] = useTableConfigQueryParam<T>(tableName);
  const [storedConfig, setStoredConfig] = useStorageItem<string>(`${tableName}:config`);

  const isInitialValue = useRef(true);

  const config = useMemo((): TableConfig<T> => {
    if (isInitialValue.current) {
      isInitialValue.current = false;

      if (!configParam) {
        setConfigQueryParam(storedConfig ?? undefined);
        return storedConfig ? JSON.parse(storedConfig) : {};
      }
    }

    return configParam ?? {};
  }, [configParam, setConfigQueryParam, storedConfig]);

  const setConfigParam = useCallback(
    (configJSON: string | undefined) => {
      const value = configJSON === '{}' ? undefined : configJSON;
      setConfigQueryParam(value);
      setStoredConfig(value);
    },
    [setConfigQueryParam, setStoredConfig],
  );

  return [config, setConfigParam] as const;
}
