import { FormControl, FormHelperText } from '@mui/material';
import { useMemo } from 'react';

import { FormSwitchProps } from '../../../common/forms';
import { styledProps } from '../../../common/styles';
import { Switch } from '../../atoms';
import { HELPER_TEXT_HEIGHT } from '../../atoms/TextField';

export function FormSwitch({ error, helperText, slotProps, ...props }: FormSwitchProps) {
  const formHelperTextProps = useMemo(
    () => styledProps({ minHeight: HELPER_TEXT_HEIGHT }, slotProps?.formHelperText),
    [slotProps?.formHelperText],
  );

  const switchProps = useMemo(() => styledProps({ flexGrow: 1 }, props), [props]);

  return (
    <FormControl error={error} {...slotProps?.formControl}>
      <Switch {...switchProps} />
      <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
    </FormControl>
  );
}
