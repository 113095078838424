import { Parser } from '@json2csv/plainjs';
import { FilterList } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  GridCallbackDetails,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowId,
  GridSortItem,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { endOfDay, startOfDay } from 'date-fns';
import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { downloadCsv } from '@sbiz/util-browser';
import { DateRange, getDateInPast } from '@sbiz/util-dates';

import { useApi } from '../../../common/api/hooks/useApi';
import { ListingParams, useApiList } from '../../../common/api/hooks/useApiList';
import { API_RESOURCES } from '../../../common/api/resources';
import {
  DEFAULT_TABLE_ACTIONS,
  TABLES,
  TABLE_PAGE_SIZE_OPTIONS,
  TableColDef,
  TableName,
  TableValidRowModel,
  getHeaderNameKeys,
  nestedValueGetter,
} from '../../../common/tables';
import { useIsEditor } from '../../../hooks/useIsEditor';
import { useTableConfig } from '../../../hooks/useTableConfig';
import { useValueFormatters } from '../../../hooks/useValueFormatters';
import { FlexBox, Span } from '../../atoms';
import { ButtonConfirmProps } from '../../atoms/Button';
import { ConfirmCloseReason } from '../../atoms/Confirm';
import { TableDataGrid, TableHeader } from '../../molecules';
import { TableDataGridProps } from '../../molecules/TableDataGrid';
import { TableHeaderProps } from '../../molecules/TableHeader';
import { RowDeletionButton } from './RowDeletionButton';

const DEFAULT_DATE_FIELD = 'createdAt';
const DEFAULT_DELETION_FIELD = 'deletedAt';
const DEFAULT_SORT_FIELD = 'createdAt';
const [DEFAULT_PAGE_SIZE] = TABLE_PAGE_SIZE_OPTIONS;

type ListingSortParam = Record<string, 1 | -1>;

export type TableConfig<T = Record<string, unknown>> = {
  companyId?: string;
  dateRange?: { end: string; start: string };
  extra?: T;
  isDeletions?: boolean;
  pagination?: GridPaginationModel;
  search?: string;
  sort?: GridSortItem;
};

export type TableProps<T extends TableValidRowModel> = {
  columns: TableColDef<T>[];
  dataGridProps?: Omit<TableDataGridProps<T>, 'columns' | 'count' | 'name' | 'onRowDeletion' | 'rows'>;
  deletionInfoText?: (params: GridRenderCellParams<T>) => string | undefined;
  ExtraActions?: (props: GridRenderCellParams<T>) => ReactNode;
  filter?: Record<string, unknown>;
  getDeletionConfirmProps?: (params: GridRenderCellParams<T>) => ButtonConfirmProps | undefined;
  getDeletionFilter?: (config: Pick<TableConfig, 'isDeletions'>) => Record<string, unknown>;
  gridApiRef?: MutableRefObject<GridApiCommunity>;
  headerProps?: Omit<
    TableHeaderProps,
    'companyId' | 'name' | 'onCompanyChange' | 'onDeletionsSwitchChange' | 'onSearchChange' | 'search'
  >;
  isRowDeletable?: (params: Pick<GridRenderCellParams<T>, 'row'>) => boolean;
  name: TableName;
  onDeletion?: (rowIds: string[]) => void;
  onListChange?: (list: { count: number; data: T[] }) => void;
  onSelectionChange?: (rows: T[]) => void;
};

export function Table<T extends TableValidRowModel>({
  columns: propsColumns,
  dataGridProps: propsDatagridProps,
  deletionInfoText,
  ExtraActions,
  filter: propsFilter,
  getDeletionConfirmProps,
  getDeletionFilter,
  gridApiRef: propsGridApiRef,
  headerProps: propsHeaderProps,
  isRowDeletable,
  name,
  onDeletion,
  onListChange,
  onSelectionChange,
}: TableProps<T>) {
  const { actions: definitionActions, dateField, hiddenActions, resourceType, sortDirection, sortField } = TABLES[name];
  const { deletionField: resourceDeletionField, permissionsScope } = API_RESOURCES[resourceType];
  const deletionField = resourceDeletionField ?? DEFAULT_DELETION_FIELD;

  const { onPaginationModelChange, onRowSelectionModelChange, ...dataGridProps } = propsDatagridProps ?? {};
  const {
    actions: headerActions,
    deletionConfirmProps: propsHeaderDeletionConfirmProps,
    ...headerProps
  } = propsHeaderProps ?? {};

  const [selectedIdSet, setSelectedIdSet] = useState<Set<string>>(new Set());

  const isRowSelection = useRef(false);

  const { deleteMany, get } = useApi(resourceType);
  const hookGridApiRef = useGridApiRef();
  const isEditor = useIsEditor(permissionsScope);
  const [tableConfig, setTableConfigQueryParam] = useTableConfig(name);
  const isDeletionsTable = Boolean(tableConfig.isDeletions);
  const { t } = useTranslation();
  const valueFormatters = useValueFormatters();

  const actions = useMemo(() => ({ ...definitionActions, ...headerActions }), [definitionActions, headerActions]);

  const companyId = useMemo(() => tableConfig.companyId ?? '', [tableConfig.companyId]);

  const dateRange = useMemo((): DateRange | undefined => {
    if (actions?.dateRangePicker) {
      if (tableConfig.dateRange?.end && tableConfig?.dateRange?.start) {
        return { end: endOfDay(tableConfig.dateRange.end), start: startOfDay(tableConfig.dateRange.start) };
      }

      return { end: endOfDay(new Date()), start: startOfDay(getDateInPast(1, 'weeks')) };
    }
  }, [actions?.dateRangePicker, tableConfig.dateRange?.end, tableConfig?.dateRange?.start]);

  const pagination = useMemo(
    (): GridPaginationModel => tableConfig.pagination ?? { page: 0, pageSize: DEFAULT_PAGE_SIZE },
    [tableConfig.pagination],
  );

  const [search, setSearch] = useState(tableConfig.search ?? '');
  const searchParam = useMemo(() => tableConfig.search ?? '', [tableConfig.search]);

  const sort = useMemo(() => {
    if (tableConfig.sort) {
      return tableConfig.sort;
    }

    const defaultSortField = isDeletionsTable ? deletionField : DEFAULT_SORT_FIELD;
    return { field: sortField ?? defaultSortField, sort: sortDirection ?? 'desc' } as GridSortItem;
  }, [deletionField, isDeletionsTable, sortDirection, sortField, tableConfig.sort]);

  const companyFilter = useMemo(() => companyId && { company: { $oid: companyId } }, [companyId]);

  const dateFilter = useMemo(() => {
    if (dateRange?.end || dateRange?.start) {
      return {
        [dateField ?? DEFAULT_DATE_FIELD]: {
          ...(dateRange.end && { $lte: { $date: dateRange.end } }),
          ...(dateRange.start && { $gte: { $date: dateRange.start } }),
        },
      };
    }
  }, [dateField, dateRange?.end, dateRange?.start]);

  const deletionFilter = useMemo(
    () =>
      getDeletionFilter?.({ isDeletions: isDeletionsTable }) ?? {
        [deletionField]: isDeletionsTable ? { $ne: null } : null,
      },
    [deletionField, getDeletionFilter, isDeletionsTable],
  );

  const filter = useMemo(() => {
    const filter = { ...companyFilter, ...dateFilter, ...deletionFilter, ...propsFilter };
    return JSON.stringify(filter);
  }, [companyFilter, dateFilter, deletionFilter, propsFilter]);

  const gridApiRef = useMemo(() => propsGridApiRef ?? hookGridApiRef, [hookGridApiRef, propsGridApiRef]);

  const sortParam = useMemo(() => {
    if (!sort?.field) {
      return;
    }

    const { field: sortField, sort: sortDirection } = sort;
    const column = propsColumns.find(({ field }) => field === sortField);
    const sortFields = column?.sortFields ?? [sortField];

    const sortObject = Object.fromEntries(
      sortFields.map((field) => [field, sortDirection === 'asc' ? 1 : -1]),
    ) as ListingSortParam;

    return JSON.stringify(sortObject);
  }, [propsColumns, sort]);

  const listingParams = useMemo(() => {
    const { page, pageSize: limit } = pagination;
    const skip = limit * (page ?? 0);

    const params: ListingParams = { filter, limit, skip, sort: sortParam };

    if (searchParam.length >= 3) {
      params.search = searchParam;
    }

    return params;
  }, [filter, pagination, searchParam, sortParam]);

  const {
    clear,
    data: response,
    isLoading,
  } = useApiList<typeof resourceType, T>(resourceType, { keepPreviousData: true, params: listingParams });

  const [count, rows] = useMemo(() => {
    const rowCount = response?.count ?? 0;
    return [rowCount, (rowCount && response?.data) || []];
  }, [response]);

  const isDeletionAuthorized = useMemo(() => {
    if (isDeletionsTable || hiddenActions?.includes('delete')) {
      return false;
    }

    return !permissionsScope || isEditor;
  }, [hiddenActions, isDeletionsTable, isEditor, permissionsScope]);

  const deleteRows = useCallback(
    async (rowIds: string[]) => {
      if (isDeletionAuthorized) {
        await deleteMany(rowIds);
        clear();
      }
    },
    [clear, deleteMany, isDeletionAuthorized],
  );

  const updateTableConfig = useCallback(
    <K extends keyof TableConfig>(key: K, value: TableConfig[K]) => {
      const config = { ...tableConfig };

      if (value) {
        config[key] = value;
      } else {
        delete config[key];
      }

      setTableConfigQueryParam(JSON.stringify(config));
    },
    [setTableConfigQueryParam, tableConfig],
  );

  const columns = useMemo(() => {
    const columns: Readonly<TableColDef<T>>[] = propsColumns.filter(
      ({ isHidden }) => !isHidden?.({ isDeletionsTable: Boolean(isDeletionsTable) }),
    );

    if (isDeletionsTable) {
      const deletionColumn: TableColDef<T> = { field: deletionField, valueType: 'date' };

      if (deletionField.includes('.')) {
        deletionColumn.valueGetter = nestedValueGetter;
      }

      columns.push(deletionColumn);
    }

    if (isDeletionAuthorized) {
      columns.push({
        field: 'actions',
        renderCell: (params) => {
          const confirmProps = getDeletionConfirmProps?.(params);

          return (
            <Box>
              {ExtraActions && <ExtraActions {...params} />}

              <RowDeletionButton
                confirmProps={{
                  ...confirmProps,
                  onClose: async (reason) => {
                    if (reason === 'confirm') {
                      const rowIds = [params.row._id];
                      await deleteRows(rowIds);
                      onDeletion?.(rowIds);
                    }

                    confirmProps?.onClose?.(reason);
                  },
                }}
                count={1}
                disabled={isRowDeletable?.(params) === false}
                isIconButton
                tableName={name}
                tooltipText={deletionInfoText?.(params)}
              />
            </Box>
          );
        },
        sortable: false,
      });
    }

    return columns.map((column): Readonly<TableColDef<T>> => {
      const headerName = t(getHeaderNameKeys(name, column.field));

      return {
        display: 'flex',
        flex: 1,
        headerName,
        ...(column.isFiltered && {
          renderHeader: () => (
            <FlexBox sx={{ gap: 0.5 }}>
              <FilterList
                color="primary"
                fontSize="small"
                onClick={(event) => {
                  event.stopPropagation();
                }}
                sx={{ cursor: 'default' }}
              />
              {headerName}
            </FlexBox>
          ),
        }),
        ...column,
        ...(!column.renderCell && {
          renderCell: ({ formattedValue }) => (
            <Span
              title={typeof formattedValue === 'string' ? formattedValue : undefined}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {formattedValue}
            </Span>
          ),
        }),
        ...(column.valueType && !column.valueFormatter && { valueFormatter: valueFormatters[column.valueType] }),
      };
    });
  }, [
    deleteRows,
    deletionField,
    deletionInfoText,
    ExtraActions,
    getDeletionConfirmProps,
    isDeletionAuthorized,
    isDeletionsTable,
    isRowDeletable,
    name,
    onDeletion,
    propsColumns,
    t,
    valueFormatters,
  ]);

  const exportColumns = useMemo(() => columns.filter(({ field }) => field !== 'actions'), [columns]);

  const getDataAsCsv = useCallback(
    (rows: T[], isSelection?: boolean) => {
      const formattedExportRows = rows.map((row) =>
        Object.fromEntries(
          exportColumns.map((column) => {
            const { field, valueGetter, valueFormatter } = column;
            const { headerName } = gridApiRef.current.getColumn(field);

            if (isSelection) {
              const { formattedValue } = gridApiRef.current.getCellParams(row._id, field);
              return [headerName, formattedValue];
            }

            const rawValue = row[field];
            const value = valueGetter ? valueGetter(rawValue, row, column) : row[field];
            return [headerName, valueFormatter ? valueFormatter(value) : value];
          }),
        ),
      );

      const csvParser = new Parser();
      return csvParser.parse(formattedExportRows);
    },
    [exportColumns, gridApiRef],
  );

  const handleExport = useCallback(async () => {
    let csvData = '';

    if (selectedIdSet.size) {
      const exportRows = gridApiRef.current.getSelectedRows() as Map<GridRowId, T>;
      csvData = getDataAsCsv(Array.from(exportRows.values()), true);
    } else if (rows.length) {
      const exportParams = { ...listingParams, limit: 10_000, skip: 0 };
      const { data: responseBody, error } = await get<{ count: number; data: T[] }>('', { params: exportParams });
      const exportRows = error ? [] : responseBody.data;
      csvData = getDataAsCsv(exportRows);
    }

    if (csvData) {
      const filename = name.replaceAll(/[a-z][A-Z]/g, (chars) => {
        const [firstChar, secondChar] = chars;
        return `${firstChar}-${secondChar.toLowerCase()}`;
      });

      downloadCsv(csvData, filename);
    }
  }, [get, getDataAsCsv, gridApiRef, listingParams, name, rows, selectedIdSet]);

  const isSelectionDeletable = useMemo(() => {
    if (!selectedIdSet.size) {
      return false;
    }

    for (const row of rows) {
      if (selectedIdSet.has(row._id) && isRowDeletable?.({ row }) === false) {
        return false;
      }
    }

    return true;
  }, [isRowDeletable, rows, selectedIdSet]);

  const checkboxSelection = useMemo(() => {
    const authorizedActionSet = new Set(DEFAULT_TABLE_ACTIONS);
    const hiddenActionSet = new Set(hiddenActions);

    if (!isDeletionAuthorized) {
      authorizedActionSet.delete('delete');
    }

    for (const action of authorizedActionSet) {
      if (hiddenActionSet.has(action)) {
        authorizedActionSet.delete(action);
      }
    }

    return authorizedActionSet.size > 0;
  }, [hiddenActions, isDeletionAuthorized]);

  const handleCompanyChange = useCallback(
    (selectedCompanyId: string) => {
      updateTableConfig('companyId', selectedCompanyId);
    },
    [updateTableConfig],
  );

  const headerDeletionConfirmProps = useMemo(
    () => ({
      ...propsHeaderDeletionConfirmProps,
      onClose: async (reason: ConfirmCloseReason) => {
        if (reason === 'confirm' && isSelectionDeletable) {
          const rowIds = Array.from(selectedIdSet);
          await deleteRows(Array.from(selectedIdSet));
          onDeletion?.(rowIds);
        }

        propsHeaderDeletionConfirmProps?.onClose?.(reason);
      },
    }),
    [deleteRows, isSelectionDeletable, onDeletion, propsHeaderDeletionConfirmProps, selectedIdSet],
  );

  const handleDateRangeChange = useCallback(
    (range: DateRange) => {
      const { end, start } = range;
      updateTableConfig('dateRange', { end: end.toISOString(), start: start.toISOString() });
    },
    [updateTableConfig],
  );

  const handleDeletionsSwitchChange = useCallback(
    (checked: boolean) => {
      updateTableConfig('isDeletions', checked);
    },
    [updateTableConfig],
  );

  const handlePaginationModelChange = useCallback(
    (pagination: GridPaginationModel, details: GridCallbackDetails) => {
      onPaginationModelChange?.(pagination, details);
      updateTableConfig('pagination', pagination);
    },
    [onPaginationModelChange, updateTableConfig],
  );

  const updateSelectedRows = useCallback(
    (rows?: T[]) => {
      if (onSelectionChange) {
        const selectedRows = rows ?? (Array.from(gridApiRef.current.getSelectedRows().values()) as T[]);
        onSelectionChange(selectedRows);
      }
    },
    [gridApiRef, onSelectionChange],
  );
  const debouncedUpdateSelectedRows = useDebouncedCallback(updateSelectedRows, 50, { leading: true, trailing: false });

  const handleRowSelectionModelChange = useCallback(
    (ids: string[], details: GridCallbackDetails) => {
      onRowSelectionModelChange?.(ids, details);
      const selectedIds = new Set(ids);
      setSelectedIdSet(selectedIds);
      isRowSelection.current = selectedIds.size > 0;

      const selectedRows = ids.map((id) => gridApiRef.current.getRow(id));
      debouncedUpdateSelectedRows(selectedRows);
    },
    [debouncedUpdateSelectedRows, gridApiRef, onRowSelectionModelChange],
  );

  const updateSearchParam = useDebouncedCallback((search: string) => {
    updateTableConfig('search', search);
  }, 400);

  const handleSearchChange = useCallback(
    (search: string) => {
      setSearch(search);
      updateSearchParam(search);
    },
    [updateSearchParam],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const [sort] = sortModel;
      updateTableConfig('sort', sort);
    },
    [updateTableConfig],
  );

  useEffect(() => {
    if (isRowSelection.current && response !== undefined) {
      debouncedUpdateSelectedRows();
    }
  }, [debouncedUpdateSelectedRows, response]);

  useEffect(() => {
    if (response !== undefined) {
      onListChange?.({ count: response?.count ?? 0, data: response?.data ?? [] });
    }
  }, [onListChange, response]);

  return (
    <>
      <TableHeader
        actions={actions}
        companyId={companyId}
        count={count}
        dateRange={dateRange}
        deletionConfirmProps={headerDeletionConfirmProps}
        isDeletionAuthorized={isDeletionAuthorized}
        isDeletionDisabled={!isSelectionDeletable}
        isDeletionsTable={isDeletionsTable}
        name={name}
        onCompanyChange={handleCompanyChange}
        onDateRangeChange={handleDateRangeChange}
        onDeletionsSwitchChange={handleDeletionsSwitchChange}
        onExport={handleExport}
        onSearchChange={handleSearchChange}
        search={search}
        selectionCount={selectedIdSet.size}
        {...headerProps}
      />

      <TableDataGrid
        apiRef={gridApiRef}
        checkboxSelection={checkboxSelection}
        columns={columns}
        count={count}
        isLoading={isLoading}
        name={name}
        onPaginationModelChange={handlePaginationModelChange}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={pagination}
        rows={rows}
        {...dataGridProps}
      />
    </>
  );
}
