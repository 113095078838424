import { useCallback, useMemo } from 'react';

import { CompanyJson, CompanyLocation, DEFAULT_LOCATION_RADIUS } from '@sbiz/business';
import { pick } from '@sbiz/util-common';

import { useApi } from '../../../../common/api/hooks/useApi';
import { FormParts } from '../../../../common/forms';
import { useAddressAutocomplete } from '../../../../hooks/useAddressAutoComplete';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms';

type LocationFormData = Record<'address' | 'name' | 'radius', string>;

export function LocationForm({
  company,
  locationIndex,
  name,
  onSubmit,
}: { company: CompanyJson; locationIndex?: number } & DialogFormProps) {
  const { updateOne } = useApi('company');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();

  const editedLocation = useMemo(() => {
    if (typeof locationIndex === 'number') {
      return company.dropOffAreas?.[locationIndex];
    }
  }, [company.dropOffAreas, locationIndex]);

  const defaultPlace = useMemo(
    () => editedLocation?.address && pick(editedLocation?.address, ['id', 'formatted', 'provider']),
    [editedLocation?.address],
  );

  const { Autocomplete, getPlaceDetails, place } = useAddressAutocomplete({ defaultPlace });

  const defaultValues = useMemo(
    () => ({ name: editedLocation?.name, radius: (editedLocation?.radius ?? DEFAULT_LOCATION_RADIUS).toFixed(2) }),
    [editedLocation],
  );

  const parts = useMemo(
    (): FormParts<LocationFormData> => [
      { fieldName: 'name', props: { required: true } },
      () => <Autocomplete textFieldProps={{ required: true }} />,
      { fieldName: 'radius', fieldType: 'number', props: { required: true } },
    ],
    [Autocomplete],
  );

  const submitBtnProps = useMemo(() => {
    if (!place) {
      return { disabled: true };
    }
  }, [place]);

  const updateLocations = useCallback(
    async (location: CompanyLocation) => {
      const dropOffAreas = company.dropOffAreas ?? [];
      const data = { dropOffAreas };
      const options = { fetcher: { clear: 'company' }, path: 'context' } as const;

      if (typeof locationIndex === 'number') {
        if (editedLocation) {
          dropOffAreas[locationIndex] = location;
          await updateOne('', data, options);
        }
      } else {
        dropOffAreas.unshift(location);
        await updateOne('', data, options);
      }

      onSubmit();
    },
    [company.dropOffAreas, editedLocation, locationIndex, onSubmit, updateOne],
  );

  const getAddress = useCallback(() => {
    if (editedLocation && editedLocation.address.formatted === place?.formatted) {
      return { data: editedLocation.address, error: undefined };
    }

    return getPlaceDetails();
  }, [editedLocation, getPlaceDetails, place?.formatted]);

  const handleSubmit = useCallback(
    async (formData: LocationFormData) => {
      const { data: address, error } = await getAddress();

      setErrorAlert(error);

      if (!error) {
        updateLocations({ ...formData, address, fullAddress: address.formatted, radius: parseFloat(formData.radius) });
      }
    },
    [getAddress, setErrorAlert, updateLocations],
  );

  return (
    <Form
      alert={errorAlert}
      defaultValues={defaultValues}
      name={name}
      onSubmit={handleSubmit}
      parts={parts}
      submitBtnProps={submitBtnProps}
    />
  );
}
